exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-gel-js": () => import("./../../../src/pages/about-GEL.js" /* webpackChunkName: "component---src-pages-about-gel-js" */),
  "component---src-pages-courses-and-fees-js": () => import("./../../../src/pages/courses-and-fees.js" /* webpackChunkName: "component---src-pages-courses-and-fees-js" */),
  "component---src-pages-familiarisation-test-upgrade-js": () => import("./../../../src/pages/familiarisation-test-upgrade.js" /* webpackChunkName: "component---src-pages-familiarisation-test-upgrade-js" */),
  "component---src-pages-flex-check-js": () => import("./../../../src/pages/flex-check.js" /* webpackChunkName: "component---src-pages-flex-check-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-share-irp-result-js": () => import("./../../../src/pages/share-irp-result.js" /* webpackChunkName: "component---src-pages-share-irp-result-js" */),
  "component---src-pages-share-result-js": () => import("./../../../src/pages/share-result.js" /* webpackChunkName: "component---src-pages-share-result-js" */),
  "component---src-templates-how-flex-works-js": () => import("./../../../src/templates/how-flex-works.js" /* webpackChunkName: "component---src-templates-how-flex-works-js" */),
  "component---src-templates-live-classes-js": () => import("./../../../src/templates/live-classes.js" /* webpackChunkName: "component---src-templates-live-classes-js" */),
  "component---src-templates-text-pages-js": () => import("./../../../src/templates/text-pages.js" /* webpackChunkName: "component---src-templates-text-pages-js" */)
}

